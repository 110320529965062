import React, { useEffect } from 'react';
import GuestLayout from '@/Layouts/GuestLayout';
import InputError from '@/Components/InputError';
import InputLabel from '@/Components/InputLabel';
import { Button, buttonVariants } from '@/Components/ui/button';
import { Label } from '@/Components/ui/label';
import { Switch } from '@/Components/ui/switch';
import TextInput from '@/Components/TextInput';
import {Link, useForm, usePage} from '@inertiajs/react';
import { cn } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { Icons } from '@/Components/Icons';
import SaveButton from '@/Components/Buttons/SaveButton';
import {toast} from "sonner";

interface LoginProps {
    status: string | null;
    canResetPassword: boolean;
}

const Login: React.FC<LoginProps> = ({ status, canResetPassword }) => {
    const { data, setData, post, processing, errors, reset } = useForm({
        email: '',
        password: '',
        oauth: '',
        remember: false,
    });
    const { props } = usePage();
    const { t } = useTranslation();

    useEffect(() => {
        if (status !== null) {
            toast.info(status);
        }
    }, [status]);

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        post(route('login'), {
            onError: () => reset('password')
        });
    };

    return (
        <GuestLayout>
            <div className="max-w-md mx-auto">
                <h1 className="text-3xl font-bold text-center mb-8">
                    {t('auth.loginForm.login')}
                </h1>
                <div className="flex justify-center items-center gap-x-4">
                    <a href={route('auth.oauth.redirect', { provider: 'linkedin' })}>
                        <Button
                            variant="outline"
                            className={cn('border-2', props.errors.oauth ? 'border-red-300' : '')}
                        >
                            <Icons.linkedin className="w-6 h-6" />
                        </Button>
                    </a>
                    <a href={route('auth.oauth.redirect', { provider: 'google' })}>
                        <Button
                            variant="outline"
                            className={cn('border-2', props.errors.oauth ? 'border-red-300' : '')}
                        >
                            <Icons.google className="w-6 h-6" />
                        </Button>
                    </a>
                    <a href={route('auth.oauth.redirect', { provider: 'facebook' })}>
                        <Button
                            variant="outline"
                            className={cn('border-2', props.errors.oauth ? 'border-red-300' : '')}
                        >
                            <Icons.facebook className="w-6 h-6" />
                        </Button>
                    </a>
                </div>

                <InputError message={props.errors.oauth} className="mt-4 text-center mx-auto" />
            </div>
            <div className="relative flex py-8 items-center">
                <div className="flex-grow border-t border-gray-400"></div>
                <span className="flex-shrink mx-4 text-gray-400">
                    {t('generic.or').toUpperCase()}
                </span>
                <div className="flex-grow border-t border-gray-400"></div>
            </div>
            <form onSubmit={submit}>
                <div>
                    <InputLabel htmlFor="email" value={t('auth.email')} />

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className={cn('mt-1 block w-full', errors.email ? 'border-2 border-red-200' : '')}
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData('email', e.target.value)}
                    />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4">
                    <InputLabel htmlFor="password" value={t('auth.password')} />

                    <TextInput
                        id="password"
                        type="password"
                        name="password"
                        value={data.password}
                        className={cn('mt-1 block w-full', errors.password ? 'border-2 border-red-200' : '')}
                        autoComplete="current-password"
                        onChange={(e) => setData('password', e.target.value)}
                    />

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="flex items-center mt-4 space-x-2">
                    <Switch
                        id="remember-me"
                        onCheckedChange={(value) => setData('remember', value)}
                        checked={data.remember}
                    />
                    <Label htmlFor="remember-me">
                        {t('auth.rememberMe')}
                    </Label>
                </div>

                <div className="flex items-center justify-end mt-4">
                    {canResetPassword && (
                        <Link
                            href={route('password.request')}
                            className={buttonVariants({ size: 'sm', variant: 'ghost' })}
                        >
                            {t('auth.forgotPassword.title')}?
                        </Link>
                    )}

                    <SaveButton isProcessing={processing} size="sm" text={t('buttons.login')} />
                </div>
            </form>
        </GuestLayout>
    );
};

export default Login;
